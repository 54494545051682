import React, { useEffect, useState, /*useRef*/} from "react"
// import styles from "../../styles/main.module.css"

export default (props) =>  {
    const WIDTH = 1200
    const HEIGHT = 675

    const wait = Math.round(1000 / 60)
    const [divs, ] = useState([])

    const [frame, setFrame] = useState(0)
    //const page = useRef(null)

    const kill = (index) => {
        console.log(`${index} is dead`)
        divs[index].alive = false
    }

    useEffect(() => {
        if (frame >= 1/* && page*/) {
            //console.log(`${JSON.stringify(divs[0])} AND ${page.current.offsetWidth}, ${page.current.offsetHeight}`)
            divs.forEach((div) => {
                if (div.alive) {
                    if ((div.x > window.innerWidth - 120 /*page.current.offsetWidth*/ && div.dx > 0) || (div.x < 0 && div.dx < 0)) {
                        div.dx = -div.dx
                        //div.dx -= Math.round(2*Math.random() - 1)
                    }
                    if ((div.y > window.innerHeight - 50 /*page.current.offsetHeight*/ && div.dy > 0) || (div.y < 0 && div.dy < 0)) {
                        div.dy = -div.dy
                        //div.dy -= Math.round(2*Math.random() + 1)
                    }

                    div.x += div.dx
                    div.y += div.dy
                }
            })
            
            setTimeout(() => {
                setFrame(frame + 1)
            }, wait)
        }
    /* eslint-disable react-hooks/exhaustive-deps */
    }, [frame])

    useEffect(() => {
        for (let i = 0; i < Math.round(50 * Math.random()+ 1); i++) {
            divs.push({
                alive: true,
                x: Math.round(WIDTH*Math.random()),
                y: Math.round(HEIGHT*Math.random()),
                dx: Math.round(2*Math.random() + 1),
                dy: Math.round(2*Math.random() + 1),
            })
        }
        setFrame(1)
    /* eslint-disable react-hooks/exhaustive-deps */
    }, [])

    return (
        <div 
            //ref={page} // hmm
            style={{
            position: "relative",
            //left: "0%", // middle
            overflow: "visible",
            width: "100%",//WIDTH,
            height: HEIGHT,//"90%",//HEIGHT,
            border: "1px black",
        }}>
            {divs.map((div, index) => div.alive ? (
                <div
                    key={index} 
                    onClick={() => kill(index)}
                    onKeyDown={() => null}
                    role="none" // yea no
                    style={{
                        position: "absolute",
                        top: div.y,
                        left: div.x,
                        width: "40px",
                        height: "40px",
                        backgroundColor: "black",
                }}>
                    <text style={{
                        position: "relative",
                        left: "50%-16px",
                        top: "50%-16px",
                        textAlign: "center",
                        color: "white",
                        fontSize: "16px",
                    }}>
                        {index}
                    </text>
                </div>
            ) : null )}
        </div>
    )
}